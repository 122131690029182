.BeforeAfter-Word-Button {
    width:100px;
    height:50px;
    border-style: ridge;
}

.BeforeAfter-Word-Button-Used {
    color: darkgray;
}

.BeforeAfter-Word-label {
    width: 75px;
    height: 25px;
    background-color: lightskyblue;
    padding:5px;
    border: 1px solid;
    border-color: black;
}