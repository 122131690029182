.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-background {
  height: 100vh;
  /* background-color: lightblue; */
  background-color: lightgray;
  font-family: Verdana;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-button {
  background-color:skyblue;
  border-radius: 5px;
  border-width: 3px;
  border-color: darkcyan;
  border-style: solid;
  margin: 2px;

}

.Ttt-button {
  width:50px;
  height:50px;
  border-style: ridge;
}

.Spacer {
  margin-top: 10px;
  margin-bottom: 10px;
}

.TabView-Style {
  background-color: skyblue;
  height: 50px;
}

.TabView-Tab-Bar {
  display: flex;
  flex-direction: row;
}

.TabView-Tab-Container {
  position: absolute;
}

.TabView-Tab {
  background-color: skyblue;
  border-radius: 0 0 20px 0;
  height: 50px;
  margin-left: -20px;
  padding-left: 30px;
  padding-right: 15px;
}

.TabView-Tab-Selected {
  background-color: cyan;
}

.TabView-Content {
  margin-left: 10px;
}