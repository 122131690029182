.Game-Background {
    height: 100vh;
    background-color: #a7bad4;
}

.Game-Content {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.Heading-Row {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.Heading-Label {
    margin-top: auto;
    margin-bottom: auto;
    padding: 15px;
    text-align: center;
    font-size: 20pt;
    font-weight: bold;
    width: 100%;
}

.Heading-Image {
    width: 15%;
    max-width: 100px;
    height: auto;
}

.Name-Label {
    padding-right: 5px;
    font-weight: bold;
}

.Name-Field {
    margin-right: 5px;
    background-color: transparent;
    color: black;
    padding: 5px;
    border-radius: 5px;
    border-width: 2px;
    border-color: rgb(78, 98, 128);
}

.Button {
    background-color: rgb(78, 98, 128);
    color: white;
    padding: 5px;
    border-radius: 5px;
    border-width: 2px;
    border-style: solid;
    border-color: white;
}

.Confirm-Button {
    background-color: red;
    margin-right: 5px;
}

.Game-Dialog {
    background-color: #a7bad4;
    width: 80%;
    margin: auto;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    padding: 15px;
    border-radius: 10px;
    max-height: 80%;
    overflow-y: auto;
}

.Dialog-Label {
    text-align: center;
    font-weight: bold;
}

.Div-Center {
    display: flex;
    justify-content: center;
}

.Player-Label {
    padding: 15px;
    text-align: center;
    font-size: 15pt;
    font-weight: bold;
}

.Player-Button {
    margin: 5px;
}

.Cursive {
    font-size: 20pt;
    font-family: 'Brush Script MT', cursive;
}